import { AxiosError } from "axios";

export const ASSETS_ROUTES = {
    IMGS: {
        logo: "/images/global/WinWinGo-logo-24-new.jpg",
        techAvatarUrl: "/images/techs/tech-avatar.jpeg",
        labAvatarUrl: "/images/global/default_dl_logo.png",
    },
};

//pagination size has to be the same as offset from backend query.
export const TECH_SEARCH_PAGINATION_SIZE = 12;
export const LAB_SEARCH_PAGINATION_SIZE = 10;
export const GENERAL_PAGINATION_SIZE = 10;

export const ERROR_API_FRAUD = "Spam / Advertising alert!";

export const ERROR_IS_FRAUD = (error: any) => {
    const fraudError = (error as AxiosError<{ error: string }>).response?.data;
    if (fraudError && fraudError.error === ERROR_API_FRAUD) return true;
    return false;
};
