import { useEffect } from 'react';

export const WelcomeModal = () => {
    useEffect(() => {
        const modalElement = document.getElementById('welcomeModal');

        if (modalElement) {
            const modal = new window.bootstrap.Modal(modalElement);
            modal.show();
        }
    }, []);

    return (
        <div className="modal fade" id="welcomeModal" tabIndex={-1} aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark text-white rounded mx-3">
                    <div className="modal-header justify-content-center border-0">
                        <h1 className="modal-title highlighted-title" id="modalLabel">Welcome Aboard!</h1>
                        {/* <button type="button" className="btn-close btn-close-white border border-light border-3" data-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className="modal-body text-center border-0">
                        <h4>Let’s start your next chapter!</h4>
                        <br />
                        <h5 className='lh-base px-4'> Complete your profile to get the most out of our platform. This will help us match you with the best labs!</h5>
                    </div>
                    <div className="modal-footer border-0">
                        <button type="button" className="btn btn-info w-100" data-dismiss="modal">Let's Go!</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
