import { useEffect, useState } from "react";

export const evaluators = {
    lessThan: (p: number) => (val: number) => val < p,
    lessOrEqualThan: (p: number) => (val: number) => val <= p,
    biggerThan: (p: number) => (val: number) => val > p,
    biggerOrEqualThan: (p: number) => (val: number) => val >= p,
};

type TUseWindowSizeMatch = {
    condition: (v: number) => boolean;
};
export const useWindowSizeMatch = ({ condition }: TUseWindowSizeMatch) => {
    const [conditionFulfilled, setConditionFulfilled] = useState<{
        fulfilled: boolean;
    }>({ fulfilled: condition(window.innerWidth) });

    useEffect(() => {
        const handleResize = () => {
            const evaluationResult = condition(window.innerWidth);
            setConditionFulfilled((prev) => {
                console.log("Angel: handleRezie: ", window.innerWidth);
                if (prev.fulfilled === evaluationResult) return prev;
                return {
                    fulfilled: evaluationResult,
                };
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        conditionFulfilled,
    };
};
