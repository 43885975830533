import { useEffect, useRef, useState } from "react"
import { useAuthContext } from "../../../contexts/auth-context"
import { NotificationProvider } from "../../../contexts/notification-context"
import { useFileManagement } from "../../../services/file-management"
import { Header } from "../../common/header"
import { Footer } from "../main-page/footer"
import { TechList, UnreadInvitationsList } from "./techList"
import { getCacheKillQueryString } from "../../../lib/common/api"
import { useRouteNavigation } from "../../../lib/common/routes"
import { useUserService } from "../../../services/users"
import { DashboardNotifications } from "../tech-dashboard/dashboard-notifications"
import { TRefLabDashboard, wizardLabDashboard } from "./wizard-lab-dashboard"
import { BlockedUserNotice } from "../../common/block-notice"
import { ASSETS_ROUTES } from "../../../lib/common/constants"
import { useLabs } from "../../../services/laboratories"
import { TMatchInfo } from "../../../lib/types/general"
import { MatchList } from "./matches"
import { useTechnicians } from "../../../services/technicians"
import { Spinner } from "../../common/spinner"

export const DentalLabDashboard = () => {
    const { userInfo } = useAuthContext();
    const { navigateToRoute } = useRouteNavigation();
    const { getImagePath } = useFileManagement();
    const { getMatches, state } = useLabs();

    const { navigateToMyAccountClickHandler } = useUserService();

    const [matches, setMatches] = useState<TMatchInfo[]>([]);
    const [disableWizard, setDisableWizard] = useState(true);


    // Wizard Component Logic Starts
    const wizardRefs: TRefLabDashboard = {
        refs: {
            searchBtnRef: useRef<HTMLButtonElement>(null),
            editProfileRef: useRef<HTMLButtonElement>(null),
            suggestionsRef: useRef<HTMLDivElement>(null),
            techlistRef: useRef<HTMLLinkElement>(null),
            filterRef: useRef<HTMLLinkElement>(null),
            pendinglistRef: useRef<HTMLDivElement>(null),
        }
    };

    const { showPopover, wizardComponent } = wizardLabDashboard(wizardRefs);
    const { getPosForTechs, industryRoles } = useTechnicians();

    useEffect(() => {
        setDisableWizard(true)
        Promise.all([getPosForTechs(), getMatches()])
            .then(([posResult, matchesResult]) => {
                setMatches(matchesResult);
                setDisableWizard(false);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <NotificationProvider>

                <Header title={`${userInfo?.name} Dashboard`} />
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9 px-0 px-lg-3">
                                <div data-testid="test-bg-img" className="mb-4 rounded dashboard-background-container" style={{ backgroundImage: `url(${getImagePath("background", "fixed", userInfo?.id) + '?' + getCacheKillQueryString()})` }}>
                                    <div className="row">
                                        <div className="col-lg-3 py-4 text-center">
                                            <img
                                                className="rounded-circle border border-2 border-light dashboard-logo"
                                                alt="hgap"
                                                src={getImagePath("logo", "fixed", userInfo?.id) + '?' + getCacheKillQueryString()}
                                                data-testid="test-logo-img"
                                                onError={(e) => { (e.target as HTMLImageElement).src = ASSETS_ROUTES.IMGS.labAvatarUrl }}
                                            />
                                        </div>
                                        <div className="col-lg-4"></div>
                                        <div className="col-lg-4 d-flex align-items-center flex-column py-4">
                                            <button
                                                ref={wizardRefs.refs.searchBtnRef}
                                                disabled={!!!userInfo?.enabled}
                                                className="btn btn-primary w-75 text-white "
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    navigateToRoute("dentalTechSearch", "", true)
                                                }}
                                            >
                                                Search Technicians
                                            </button>
                                            <button
                                                ref={wizardRefs.refs.editProfileRef}
                                                className="btn btn-secondary mt-2 w-75"
                                                onClick={navigateToMyAccountClickHandler}
                                                disabled={!!!userInfo?.enabled}
                                            >
                                                Edit Lab Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 p-4 gray-container gray-container__tips overflow-auto border border-1 border-light rounded" >
                                <h6 className="fw-bold">Helpful Tips</h6>
                                <hr />
                                <p><small>
                                    Explore how to navigate through WinWinGo! Click here to begin the walkthrough.
                                    <button
                                        className="btn btn-info btn-sm border-0 rounded py-0 mx-2 px-5 text-white"
                                        onClick={() => {
                                            showPopover()
                                        }}
                                        disabled={!!!userInfo?.enabled || disableWizard}
                                    >
                                        Start
                                    </button>
                                </small></p>
                                <hr />
                                <p><small>
                                    <strong>Take the time to build a really nice Lab profile. </strong>
                                    A happy technician needs to see and envision their life in your town and not just your lab.
                                </small></p>
                                <hr />
                                <p><small><strong>Your "Open positions", "Company philosophy" and "About our area" entries will help us match you to your best hires.</strong></small></p>
                                <hr />
                                <p><small>Consider outlining Sign-on bonuses. This is a huge incentive for techs.</small></p>
                                <hr />
                                <p><small>
                                    Consider covering the costs for a potential technician (airfare and hotel) for a week to have a proper meet and greet as well as see their working talents.
                                </small></p>
                                <hr />
                                <p><small>
                                    Send out invites to multiple technicians with your needed skillsets.
                                    You never know where your next great hire will come from.
                                </small></p>
                                <hr />
                                <p><small>
                                    Consider always having an active profile with WinWinGo.
                                    Life is filled with changes and a technician that was just looking months ago might now be ready to make a change.
                                </small></p>
                                <hr />
                                <p><small>Make a statement about your area that puts your best foot forward.</small></p>
                                <hr />
                                <p><small>
                                    Consider changing and updating your lab profile often embracing the seasons.
                                </small></p>
                                <hr />
                                {/* <p><small>Include any and all Social Media profile links you have. </small></p>
                                <hr /> */}
                                <p><small>Load your gallery with images of your area that showcases what differentiates your area to others. Be sure they are public domain or you have approval from the copyright holder.</small></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-4 mt-lg-0">
                    <div className="container">
                        <div className="row">
                            {!!userInfo?.enabled && (
                                <>
                                    <div className="col-lg-9 px-0 px-lg-3">
                                        <div ref={wizardRefs.refs.suggestionsRef}
                                            className="ai-container rounded border-light p-4 mb-3">
                                            <div className="d-flex justify-content-between">
                                                <h6 className="fw-bold lh-base ai-color"> <i className="fa-solid fa-wand-magic-sparkles me-3 fa-lg" ></i>
                                                    WinWinGo AI-Powered Recommendations</h6>
                                                <div data-toggle="collapse" data-target="#AiInfo" aria-expanded="true" aria-controls="AiInfo" role="button">
                                                    <i className="fa-solid fa-circle-info ai-color"></i></div>
                                            </div>
                                            <div className="collapse p-0" id="AiInfo">
                                                <div className="lh-sm ai-color">
                                                    <p><small>Welcome to your personalized AI-powered recommendation section! Here, we'll present a curated list of technicians who perfectly fit the positions you need to fill, based on their skills, hobbies, and interests. These suggestions are tailored based on your company's philosophy, your location description and available positions.</small></p>
                                                </div>
                                            </div>
                                            {state === "working" && (
                                                <>
                                                    <Spinner />
                                                </>
                                            )}
                                            {matches !== undefined && matches.length > 0 && state === "idle" &&
                                                <MatchList matches={matches} industryRoles={industryRoles} />
                                            }
                                            {matches.length === 0 && state === "idle" && (
                                                <p className="p-2"><strong>No matches yet?</strong>  Ensure your job postings include the positions you're hiring for and relevant details of your location to help us find the best technicians for your lab.</p>
                                            )}
                                        </div>
                                        {/* Tech List Section Start */}
                                        <div className="gray-container border border-1 rounded border-light p-3 min-vh-75" ref={wizardRefs.refs.techlistRef}>
                                            {industryRoles.length > 0 && <TechList
                                                industryRoles={industryRoles}
                                                refFilter={wizardRefs.refs.filterRef}
                                                refTechList={wizardRefs.refs.techlistRef} />}
                                        </div>

                                        {/* Invitations Sent To Section Start */}
                                        <div className="gray-container border border-1 rounded border-light p-3 min-vh-25">
                                            <h6 className="p-3" ref={wizardRefs.refs.pendinglistRef}>Sent invitation requests</h6>
                                            <UnreadInvitationsList refPendingList={wizardRefs.refs.pendinglistRef} />
                                        </div>
                                    </div>

                                    {/* Notifications Section Start */}
                                    <div className="col-lg-3 px-0">
                                        <div className="d-none d-lg-block gray-container border border-1 rounded border-light p-3 min-vh-25 overflow-auto">
                                            <h6 className="py-3 px-2">Notifications</h6>
                                            <DashboardNotifications />
                                        </div>
                                    </div>
                                </>
                            )}
                            {!!!userInfo?.enabled && (
                                < BlockedUserNotice />
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
                {wizardComponent}
            </NotificationProvider>
        </>
    )
}