import { TMessageStatus, TProcessStates } from "../../lib/types/general";
import { TUserTypes } from "../../lib/types/users";
import { useMessages } from "../../services/notifications";

export const InviteButton = ({
    userToInviteId,
    userToInviteInvitationState,
    userType,
}: {
    userToInviteId: string;
    userToInviteInvitationState?: TMessageStatus;
    userType: TUserTypes;
}) => {
    const { sendInvitation, sendInviteState } = useMessages(userToInviteId);
    const isDone: TProcessStates | undefined = userToInviteInvitationState
        ? "done"
        : undefined;

    return (
        <button
            className={
                (isDone || sendInviteState) === "done"
                    ? "btn btn-outline-dark w-100 rounded-3"
                    : "btn btn-primary w-100 rounded-3 fw-semibold"
            }
            data-testid="invite-button"
            disabled={(isDone || sendInviteState) !== "idle"}
            onClick={(e) => {
                e.preventDefault();
                sendInvitation();
            }}
        >
            {(isDone || sendInviteState) === "idle" &&
                userType === "lab" &&
                "CONTACT"}
            {(isDone || sendInviteState) === "idle" &&
                userType === "tech" &&
                "INVITE"}

            {(isDone || sendInviteState) === "error" && (
                <>
                    <i
                        data-testid="error"
                        className="fa-solid fa-circle-exclamation"
                    ></i>{" "}
                    Please try again
                </>
            )}

            {(isDone || sendInviteState) === "working" && (
                <>
                    <i
                        data-testid="loading-spinner"
                        className="fa-solid fa-spinner fa-spin"
                    ></i>{" "}
                    sending
                </>
            )}

            {(isDone || sendInviteState) === "done" && (
                <>
                    <i
                        data-testid="sent"
                        className="fa-solid fa-envelope-circle-check "
                    ></i>{" "}
                    SENT
                </>
            )}
        </button>
    );
};
