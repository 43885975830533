import React, { useEffect } from "react";
//import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.bundle.min";
//import './style.css';
import { MainPage } from "./components/sections/main";
import { Routes, Route } from "react-router-dom";
import { DentalLabSignup } from "./components/sections/dental-lab-signup";
import { DentalTechSignup } from "./components/sections/dental-tech-signup";
import { DentalTechDashboard } from "./components/sections/tech-dashboard/dental-tech-dashboard";
import { useAuthContext } from "./contexts/auth-context";
import { DentalLabDashboard } from "./components/sections/lab-dashboard/dental-lab-dashboard";
import { SubscriptionSelection } from "./components/sections/subscription-selection";
import { SubscriptionOKTech } from "./components/sections/subscription-ok/subscription-ok-tech";
import { DentalTechInfo } from "./components/sections/dental-tech-info";
import { SubscriptionOKLab } from "./components/sections/subscription-ok/subscription-ok-lab";
import { DentalLabInfo } from "./components/sections/dental-lab-info";
import { DentalTechSearch } from "./components/sections/tech-search";
import { DentalLabSearch } from "./components/sections/lab-search";
import { HiringLabs } from "./components/sections/hiring-labs";
import { useLocation } from "react-router-dom";
import { useUserService } from "./services/users";
import { Layout } from "./components/sections/backoffice/layout";
import { AdminMainSection } from "./components/sections/backoffice/main";
import { AdminReportMain } from "./components/sections/backoffice/reports/report-main";
import { AdminLabInfo } from "./components/sections/backoffice/user-info/lab-info";
import { AdminTechInfo } from "./components/sections/backoffice/user-info/tech-info";
import { TermsOfUse } from "./components/sections/legal/terms-of-use";
import { PrivacyPolicy } from "./components/sections/legal/privacy-policy";
import { CookiePolicy } from "./components/sections/legal/cookie-policy";
import { ContactUs } from "./components/sections/contact";
import { PositionsAdmin } from "./components/sections/backoffice/admin/positions-admin";
import { NotificationProvider } from "./contexts/notification-context";
import { Story } from "./components/sections/story";
import { AboutMark } from "./components/sections/aboutMark";
import { Pricing } from "./components/sections/pricing";

declare global {
    interface Window {
        wwgInactivityTimer: any;
    }
}

window.wwgInactivityTimer = undefined;

function resetTimer(fnLogout: () => void) {
    clearTimeout(window.wwgInactivityTimer);
    window.wwgInactivityTimer = setTimeout(() => {
        fnLogout();
    }, 1_800_000); //<--- equivalent to 30 minutes
}

function App() {
    const { SecureSection, isLoggedIn } = useAuthContext();
    const { logOutUserDueToInactivity } = useUserService();
    const locationInfo = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
        const lWindow: any = window;
        if (isLoggedIn) {
            const lResetTimer = () => resetTimer(logOutUserDueToInactivity);
            lWindow.onload = lResetTimer;
            lWindow.onmousemove = lResetTimer;
            lWindow.onkeypress = lResetTimer;
            lResetTimer();
        } else {
            lWindow.onload = undefined;
            lWindow.onmousemove = undefined;
            lWindow.onkeypress = undefined;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInfo.pathname]);
    return (
        <>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route
                    path="/home"
                    element={
                        <SecureSection types={["lab", "tech", "admin"]}>
                            <NotificationProvider>
                                <MainPage />
                            </NotificationProvider>
                        </SecureSection>
                    }
                />
                <Route path="/logoutduetoinactivity" element={<MainPage />} />
                <Route path="/signup/tech" element={<DentalTechSignup />} />
                <Route path="/signup/lab" element={<DentalLabSignup />} />
                <Route path="/hiringlabs/:regionId" element={<HiringLabs />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookie-policy" element={<CookiePolicy />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/story" element={<Story />} />
                <Route path="/about-us" element={<AboutMark />} />
                <Route path="/pricing" element={<Pricing />} />

                <Route
                    path="/dashboard/tech"
                    element={
                        <SecureSection type="tech" allowUserEnabled={true}>
                            <DentalTechDashboard />
                        </SecureSection>
                    }
                />

                <Route
                    path="/account/tech"
                    element={
                        <SecureSection type="tech">
                            <DentalTechInfo editable={true} />
                        </SecureSection>
                    }
                />

                <Route
                    path="/info/tech/:userId"
                    element={
                        <SecureSection type="lab">
                            <DentalTechInfo />
                        </SecureSection>
                    }
                />
                <Route
                    path="/info/lab/:userId"
                    element={
                        <SecureSection type="tech">
                            <DentalLabInfo />
                        </SecureSection>
                    }
                />
                <Route
                    path="/dashboard/lab"
                    element={
                        <SecureSection type="lab" allowUserEnabled={true}>
                            <DentalLabDashboard />
                        </SecureSection>
                    }
                />
                <Route
                    path="/account/lab"
                    element={
                        <SecureSection type="lab">
                            <DentalLabInfo editable={true} />
                        </SecureSection>
                    }
                />
                <Route
                    path="/subscription/selection"
                    element={
                        <SecureSection>
                            <SubscriptionSelection />
                        </SecureSection>
                    }
                />
                <Route
                    path="/paymentok/tech/:subscriptionPlanId/:months/:checkoutsessionid"
                    element={
                        <SecureSection>
                            <SubscriptionOKTech />
                        </SecureSection>
                    }
                />
                <Route
                    path="/paymentok/lab/:subscriptionPlanId/:months/:checkoutsessionid"
                    element={
                        <SecureSection>
                            <SubscriptionOKLab />
                        </SecureSection>
                    }
                />
                <Route
                    path="/search/techs"
                    element={
                        <SecureSection type="lab">
                            <DentalTechSearch />
                        </SecureSection>
                    }
                />
                <Route
                    path="/search/labs"
                    element={
                        <SecureSection type="tech">
                            <DentalLabSearch />
                        </SecureSection>
                    }
                />
                <Route
                    path="/backOffice/dashboard"
                    element={
                        <SecureSection type="admin" allowUserEnabled={true}>
                            <Layout title="Dashboard">
                                <AdminMainSection />
                            </Layout>
                        </SecureSection>
                    }
                />
                <Route
                    path="/backOffice/report-list"
                    element={
                        <SecureSection type="admin" allowUserEnabled={true}>
                            <Layout title="Reports">
                                <AdminReportMain />
                            </Layout>
                        </SecureSection>
                    }
                />
                <Route
                    path="/backOffice/userInfo/lab/:userId"
                    element={
                        <SecureSection type="admin" allowUserEnabled={true}>
                            <Layout title="Reports">
                                <AdminLabInfo />
                            </Layout>
                        </SecureSection>
                    }
                />
                <Route
                    path="/backOffice/userInfo/tech/:userId"
                    element={
                        <SecureSection type="admin" allowUserEnabled={true}>
                            <Layout title="Reports">
                                <AdminTechInfo />
                            </Layout>
                        </SecureSection>
                    }
                />
                <Route
                    path="/backOffice/admin-positions"
                    element={
                        <SecureSection type="admin" allowUserEnabled={true}>
                            <Layout title="Positions Administrator">
                                <PositionsAdmin />
                            </Layout>
                        </SecureSection>
                    }
                />
                <Route path="*" element={<>NOTHING HERE</>} />
            </Routes>
        </>
    );
}

export default App;
