import { useRouteNavigation } from "../../../lib/common/routes";
import { RouteEnumType } from "../../../lib/types/routes";

type TPricingData = {
    card_name?: string;
    title: string;
    price: string;
    features: string[];
    duration?: string;
    sign_up_form: RouteEnumType;
    tag?: string;
};

const pricingData: TPricingData[] = [
    {
        card_name: "Tech",
        title: "Techs Are Always Free",
        price: "Free",
        features: [
            "Unlimited job opportunities",
            "Access to all lab postings",
            "Direct applications to top dental labs",
            "Powered AI suggestions based on hobbies, interests, and skills",
        ],
        sign_up_form: "signupTech",
        tag: "Free!",
    },
    {
        card_name: "Lab",
        title: "Labs Monthly Subscription",
        price: "$299",
        duration: "/ month",
        features: [
            "Direct communication between labs and techs",
            "AI tech/lab matching feature",
            "Target techs interested in your area",
            "Dental industry-specific features",
        ],
        sign_up_form: "signupLab",
        tag: "1st Month Free!",
    },
];

export const PricingBlade = () => {
    const { navigateRouteClickHandler } = useRouteNavigation();

    return (
        <div className="container-fluid bg-light pricing-bg">
            <div className="container">
                <div className="row justify-content-center py-5">
                    <h1 className="home-title-h1">Simple Pricing</h1>
                    <p className="mb-5 lead">
                        Techs are always free, and labs get premium features
                        with just one subscription—$299/month, including the
                        first month free!
                    </p>

                    <div className="row justify-content-center">
                        {pricingData.map((plan, index) => (
                            <div className="col-lg-6" key={index}>
                                <div className="card shadow mb-4 border-0">
                                    <div className="pb-3">
                                        <h4 className="text-end mt-3">
                                            {" "}
                                            <span className="badge rounded-0 rounded-start px-5 text-bg-dark shadow">
                                                {plan.tag}
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="px-4 px-md-5 pb-3">
                                        <h2 className="text-info fw-bold highlighted-title pb-3">
                                            {plan.card_name} Subscription
                                        </h2>
                                        <h1 className="fw-bold">
                                            {plan.price}{" "}
                                            {plan.duration && (
                                                <span
                                                    style={{
                                                        fontSize: "medium",
                                                    }}
                                                >
                                                    {" "}
                                                    {plan.duration}
                                                </span>
                                            )}
                                        </h1>
                                        <div style={{ minHeight: "9rem" }}>
                                            <ul className="mt-4">
                                                {plan.features.map(
                                                    (feature, i) => (
                                                        <li key={i}>
                                                            {feature}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row g-3 px-5 pb-5">
                                        <div className="col-lg-6">
                                            <button
                                                className="btn btn-info w-100 text-white"
                                                onClick={(e) =>
                                                    navigateRouteClickHandler(
                                                        "pricing",
                                                        e
                                                    )
                                                }
                                            >
                                                See more
                                            </button>
                                        </div>
                                        <div className="col-lg-6">
                                            <button
                                                className="btn btn-info w-100 text-white"
                                                onClick={(e) =>
                                                    navigateRouteClickHandler(
                                                        plan.sign_up_form,
                                                        e
                                                    )
                                                }
                                            >
                                                {plan.card_name} Sign Up
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
