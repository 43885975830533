import React, {
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useState,
} from "react";
import { TApiLab } from "../../../lib/types/labs";
import { useLabs } from "../../../services/laboratories";
import { TGenericIdName } from "../../../lib/types/general";
import { useRouteNavigation } from "../../../lib/common/routes";
import { useFileManagement } from "../../../services/file-management";
import { useUserService } from "../../../services/users";
import { useServiceContext } from "../../../contexts/service";
import {
    ASSETS_ROUTES,
    LAB_SEARCH_PAGINATION_SIZE,
} from "../../../lib/common/constants";
import { Pagination } from "../../common/pagination";
import { Spinner } from "../../common/spinner";
import { useNotificationContext } from "../../../contexts/notification-context";
import { phoneFormat } from "../../../lib/common/validation";
import { MessageBtn } from "../../common/message-button";

type TLabRowProps = {
    info: TApiLab;
    regionList: TGenericIdName[];
    removeContactCallback: () => void;
};
export const DashboardLabRow = ({
    info,
    regionList,
    removeContactCallback,
}: TLabRowProps) => {
    const { navigateRouteClickHandler, getRoute } = useRouteNavigation();
    const { getImagePath } = useFileManagement();
    const { removeFromMyContactList } = useUserService();
    const ProfileLink = ({ children }: PropsWithChildren) => (
        <a
            className="btn-link text-decoration-none"
            href={getRoute("dentalLabInfo", info.id)}
            onClick={(e) =>
                navigateRouteClickHandler("dentalLabInfo", e, info.id, true)
            }
        >
            {children}
        </a>
    );
    const {
        chats: { setShowChat },
    } = useServiceContext();

    const imgUrl = info?.images?.logo
        ? getImagePath(info.images.logo, "fixed", info.id)
        : ASSETS_ROUTES.IMGS.labAvatarUrl;

    const deleteContactClickHandler = async (
        e: MouseEvent,
        contactUserId: string
    ) => {
        e.preventDefault();
        e.stopPropagation();
        setShowChat(false);
        await removeFromMyContactList(contactUserId);
        removeContactCallback();
    };

    const phoneFormatHandler = (phoneNumberString: string) => {
        const match = phoneNumberString.match(phoneFormat);
        if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return "Phone number not verified";
    };

    return (
        <div className="list-group-item list-group-item-action border border-light py-4">
            <div className="row text-center gap-3">
                <div className="col-md-3 ps-4 d-flex justify-content-center justify-content-md-start align-items-center">
                    <img
                        className="rounded-circle list-logo "
                        onClick={(e) =>
                            navigateRouteClickHandler(
                                "dentalLabInfo",
                                e,
                                info.id,
                                true
                            )
                        }
                        alt="laboratory logo"
                        role="button"
                        src={imgUrl}
                    />
                </div>
                <div className="col-md-5 text-center text-md-start ">
                    <p>
                        <ProfileLink>
                            <strong>{info.name}</strong>
                        </ProfileLink>
                    </p>
                    <small>
                        <p>{info.other?.specialty}</p>
                        {info.other.desiredRegion && (
                            <p>
                                Region:{" "}
                                {
                                    regionList.find(
                                        (region) =>
                                            region.id ===
                                            info.other.desiredRegion
                                    )?.name
                                }
                            </p>
                        )}
                        <p>
                            {info.other?.location} {info.other?.zip}
                        </p>
                        {info.other.phone && (
                            <p>
                                {" "}
                                Phone Number:{" "}
                                {phoneFormatHandler(info.other?.phone)}
                            </p>
                        )}
                    </small>
                    <a href="#/thislab">Follow this Lab</a> -{" "}
                    <ProfileLink>View Lab Profile</ProfileLink>
                </div>
                <div className="col-md-3 mt-4 mt-md-0">
                    <MessageBtn
                        userInfo={info}
                        className="btn-secondary w-75 mb-2 "
                    />
                    <button
                        onClick={(e) => deleteContactClickHandler(e, info.id)}
                        className="btn btn-danger w-75"
                    >
                        Delete
                    </button>
                </div>
            </div>
            {/* <hr /> */}
        </div>
    );
};

export const LabList = ({
    refLabList,
}: {
    refLabList?: React.RefObject<any>;
}) => {
    const { state, getLabsFromContactList, regions } = useLabs();

    const [labs, setLabs] = useState<TApiLab[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const { setActionsHandler } = useNotificationContext();

    const refreshLabs = (offset: number) => {
        getLabsFromContactList(offset).then((results) => {
            setLabs(results.labs);
            setTotalCount(results.totalCount);
            setPageNumber(offset + 1);
        });
    };

    useEffect(() => {
        setActionsHandler("refreshContactList", () => refreshLabs(0));
        refreshLabs(0);

        // eslint-disable-next-line
    }, []);

    return (
        <div className="list-group">
            {state === "working" && (
                <>
                    <Spinner />
                </>
            )}
            {state === "idle" && labs.length === 0 && (
                <p className="p-3"> You don't have any contacts yet.</p>
            )}
            {state !== "working" && labs.length > 0 && (
                <>
                    {labs.map((lab) => (
                        <DashboardLabRow
                            key={lab.id}
                            info={lab}
                            removeContactCallback={() => refreshLabs(0)}
                            regionList={regions}
                        />
                    ))}
                    {labs.length >= LAB_SEARCH_PAGINATION_SIZE && (
                        <Pagination
                            currentPage={pageNumber}
                            totalCount={totalCount}
                            itemsPerPage={LAB_SEARCH_PAGINATION_SIZE}
                            refObject={refLabList}
                            onPageChange={(offset) => {
                                refreshLabs(offset);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};
