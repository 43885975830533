import { useRef, useState } from "react";
import { useAuthContext } from "../contexts/auth-context";
import { apiRequestSecuredPost } from "../lib/common/api";
import { TProcessStates } from "../lib/types/general";

type TFileNameChangeHandler = (
    newfile?: string,
    inappropriateContent?: boolean,
    unsupportedImageType?: boolean
) => void;
type TUploadType = "image" | "resume";
type TUploadPurpose = "fixed" | "gallery";

export const useFileManagement = () => {
    const { getUserTokenId, userInfo } = useAuthContext();

    const [state, setState] = useState<TProcessStates>("idle");

    const getImagePath = (
        image: string,
        purpose: TUploadPurpose,
        userId?: string
    ) => {
        return `${process.env.REACT_APP_BUCKETEER_URL}${
            userId || userInfo?.id
        }/images/${purpose === "gallery" ? "gallery/" : ""}${image}`;
    };

    /**
     * this is used for uploading fixed data like resume, logo, background, etc.
     * @param fileName file name to assign to the file
     * @param fileContent content to upload
     * @returns
     */
    const uploadFixed = async (
        fileName: string,
        fileContent: string,
        fileType: TUploadType
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        setState("working");
        try {
            const imageResponse = await apiRequestSecuredPost<{
                fileUrl: string;
                inappropriateContent?: boolean;
                unsupportedImageType?: boolean;
            }>("files/upload", await getUserTokenId(), {
                fileName,
                fileContent,
                fileType,
            });

            const inappropriateContent =
                imageResponse.data?.inappropriateContent ?? false;
            const unsupportedImageType =
                imageResponse.data?.unsupportedImageType ?? false;

            setState("idle");
            return {
                fileUrl: fileName,
                inappropriateContent,
                unsupportedImageType,
            };
        } catch (error: any) {
            if (error.response?.data?.inappropriateContent) {
                setState("idle");
                return { fileUrl: "", inappropriateContent: true };
            }
            if (error.response?.data?.unsupportedImageType) {
                setState("idle");
                return { fileUrl: "", unsupportedImageType: true };
            } else {
                setState("idle");
                throw error;
            }
        }
    };
    /**
     * this is used for uploading images to a gallery
     * @param fileName file name to assign to the file
     * @param fileContent content to upload
     * @returns
     */
    const uploadToGallery = async (
        fileName: string,
        fileContent: string
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        setState("working");
        try {
            const imageResponse = await apiRequestSecuredPost<{
                fileUrl: string;
                inappropriateContent?: boolean;
                unsupportedImageType?: boolean;
            }>("files/uploadgallery", await getUserTokenId(), {
                fileName,
                fileContent,
            });
            const inappropriateContent =
                imageResponse.data?.inappropriateContent ?? false;
            const unsupportedImageType =
                imageResponse.data?.unsupportedImageType ?? false;
            setState("idle");

            return {
                fileUrl: fileName,
                inappropriateContent,
                unsupportedImageType,
            };
        } catch (error: any) {
            if (error.response?.data?.inappropriateContent) {
                setState("idle");
                return { fileUrl: "", inappropriateContent: true };
            } else if (error.response?.data?.unsupportedImageType) {
                setState("idle");
                return { fileUrl: "", unsupportedImageType: true };
            } else {
                setState("idle");
                throw error;
            }
        }
    };

    /**
     * used for uploading fixed images/documents like logo, background, resume
     * @param file
     * @param fileName
     * @param fileNameChangeHandler
     * @returns
     */
    const controledFileUploadFixed = (
        file: any,
        fileName: string,
        fileType: TUploadType,
        fileNameChangeHandler?: TFileNameChangeHandler
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        const promise = new Promise<{
            fileUrl: string;
            inappropriateContent?: boolean;
            unsupportedImageType?: boolean;
        }>((resolve, reject) => {
            fileNameChangeHandler && fileNameChangeHandler(undefined);

            let reader = new FileReader();
            reader.onload = async () => {
                let fileInfo = {
                    fileContent: (reader.result as string)?.split(",")[1],
                    fileName: file.name,
                };
                try {
                    const {
                        fileUrl,
                        inappropriateContent,
                        unsupportedImageType,
                    } = await uploadFixed(
                        fileName,
                        fileInfo.fileContent,
                        fileType
                    );

                    if (inappropriateContent) {
                        resolve({ fileUrl, inappropriateContent: true });
                    } else if (unsupportedImageType) {
                        resolve({ fileUrl, unsupportedImageType: true });
                    } else {
                        fileNameChangeHandler && fileNameChangeHandler(fileUrl);
                        resolve({
                            fileUrl,
                            inappropriateContent: false,
                            unsupportedImageType: false,
                        });
                    }
                } catch (error) {
                    console.error("Error occurred during file upload:", error);
                    reject(error);
                }
            };
            reader.readAsDataURL(file);
        });

        return promise;
    };
    /**
     * used for uploading images to gallery
     * @param file
     * @param fileName
     * @param fileNameChangeHandler
     * @returns
     */
    const controledFileUploadGallery = (
        file: any,
        fileName: string,
        fileNameChangeHandler?: TFileNameChangeHandler
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        const promise = new Promise<{
            fileUrl: string;
            inappropriateContent?: boolean;
            unsupportedImageType?: boolean;
        }>((resolve, reject) => {
            fileNameChangeHandler && fileNameChangeHandler(undefined);

            let reader = new FileReader();
            reader.onload = async () => {
                let fileInfo = {
                    fileContent: (reader.result as string)?.split(",")[1],
                    fileName: file.name,
                };
                try {
                    const {
                        fileUrl,
                        inappropriateContent,
                        unsupportedImageType,
                    } = await uploadToGallery(fileName, fileInfo.fileContent);

                    if (inappropriateContent) {
                        resolve({ fileUrl, inappropriateContent: true });
                    } else if (unsupportedImageType) {
                        resolve({ fileUrl, unsupportedImageType: true });
                    } else {
                        fileNameChangeHandler && fileNameChangeHandler(fileUrl);
                        resolve({
                            fileUrl,
                            inappropriateContent: false,
                            unsupportedImageType: false,
                        });
                    }
                } catch (error: any) {
                    console.error("Error occurred during file upload:", error);
                    reject(error);
                }
            };
            reader.readAsDataURL(file);
        });

        return promise;
    };

    /**
     *
     * @param file
     * @param fileName
     * @param fileType
     * @param fileNameChangeHandler
     * @returns
     */
    const imageFileChecker = async (
        file: any,
        fileName: string,
        fileType: TUploadType,
        purpose: TUploadPurpose
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        try {
            if (file.size > 10 * 1024 * 1024) {
                throw new Error("Files can't be bigger than 10 MB");
            }
            if (file.type.indexOf("image") === -1) {
                throw new Error("File should be an image");
            }

            switch (purpose) {
                case "fixed":
                    const response = await controledFileUploadFixed(
                        file,
                        fileName,
                        fileType
                    );
                    return response;

                case "gallery":
                    const galleryResponse = await controledFileUploadGallery(
                        file,
                        fileName
                    );
                    return galleryResponse;
                default:
                    throw new Error("Invalid upload purpose");
            }
        } catch (error) {
            console.error("Error occurred during image file checking:", error);
            return {
                fileUrl: "",
                inappropriateContent: true,
                unsupportedImageType: true,
            };
        }
    };

    /**
     *
     * @param file
     * @param fileName
     * @param fileType
     * @param fileNameChangeHandler
     * @returns
     */
    const documentFileChecker = async (
        file: any,
        fileName: string,
        fileType: TUploadType,
        fileNameChangeHandler?: TFileNameChangeHandler
    ) => {
        if (file.size > 10 * 1024 * 1024) {
            throw new Error("Files can't be bigger than 10 MB");

            //alert("Los archivos no puede tener más de 10 MB"); //TODO: i18n
            //return;
        }
        if (
            file.type.indexOf("application/pdf") === -1 &&
            file.type.indexOf("application/msword") === -1 && //(.doc)
            file.type.indexOf(
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" //(.docx)
            ) === -1
        ) {
            throw new Error("File should be an document PDF or DOC");
            //alert("El archivo no es un pdf o word"); //TODO: i18n
            //return;
        }

        const documentFileNameParts: string[] = file.name.split(".");
        const documentfileExtension =
            documentFileNameParts[documentFileNameParts.length - 1];
        return controledFileUploadFixed(
            file,
            `${fileName}.${documentfileExtension}`,
            fileType,
            fileNameChangeHandler
        );
    };

    /**
     *
     * @param htmlElement
     * @returns
     */
    const makeDroppableCapable = (
        htmlElement: React.RefObject<HTMLElement>,
        fileNameToAssign: string,
        uploadType: TUploadType,
        uploadPurpose: TUploadPurpose,
        fileNameChangeHandler?: TFileNameChangeHandler
    ): Promise<{
        fileUrl: string;
        inappropriateContent?: boolean;
        unsupportedImageType?: boolean;
    }> => {
        if (!htmlElement.current) {
            setTimeout(() => {
                makeDroppableCapable(
                    htmlElement,
                    fileNameToAssign,
                    uploadType,
                    uploadPurpose,
                    fileNameChangeHandler
                );
            }, 1000);
            return Promise.resolve({
                fileUrl: "",
                inappropriateContent: false,
                unsupportedImageType: false,
            });
        }

        const component = htmlElement.current;

        component.ondragover = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            component.classList.add("dropable-component");
        };

        component.ondragleave = (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            component.classList.remove("dropable-component");
        };
        component.ondrop = async (e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            component.classList.remove("dropable-component");

            component.classList.add("cssLoading", "cssLoading_linearBgLoading");

            try {
                switch (uploadType) {
                    case "resume":
                        const resumeResponse = await documentFileChecker(
                            (e as any).dataTransfer.files[0],
                            fileNameToAssign,
                            uploadType,
                            fileNameChangeHandler
                        );

                        component.classList.remove(
                            "cssLoading",
                            "cssLoading_linearBgLoading"
                        );
                        return resumeResponse.fileUrl;

                    case "image":
                        const {
                            fileUrl,
                            inappropriateContent,
                            unsupportedImageType,
                        } = await imageFileChecker(
                            (e as any).dataTransfer.files[0],
                            fileNameToAssign,
                            uploadType,
                            uploadPurpose
                        );

                        if (inappropriateContent) {
                            fileNameChangeHandler &&
                                fileNameChangeHandler(fileUrl, true);
                        } else if (unsupportedImageType) {
                            fileNameChangeHandler &&
                                fileNameChangeHandler(fileUrl, false, true);
                        } else {
                            fileNameChangeHandler &&
                                fileNameChangeHandler(fileUrl, false);
                        }

                        component.classList.remove(
                            "cssLoading",
                            "cssLoading_linearBgLoading"
                        );
                        return Promise.resolve({
                            fileUrl: "",
                            inappropriateContent: false,
                            unsupportedImageType: false,
                        });
                    default:
                        throw new Error("upload type incorrect");
                }
            } catch (error) {
                console.error("Error occurred:", error);
                component.classList.remove(
                    "cssLoading",
                    "cssLoading_linearBgLoading"
                );
                return Promise.resolve({
                    fileUrl: "",
                    inappropriateContent: true,
                    unsupportedImageType: true,
                });
            }
        };

        return Promise.resolve({
            fileUrl: "",
            inappropriateContent: false,
            unsupportedImageType: false,
        });
    };

    return {
        state,
        makeDroppableCapable,
        getImagePath,
        imageFileChecker,
    };
};

type TFileUploadComponentProps = {
    relatedComponent: React.RefObject<HTMLElement>;
    fileToolTipText: string;
    tooltipPosition: "left" | "right" | "down" | "downRight";
};

export const FileUploaderComponent = ({
    relatedComponent,
    fileToolTipText,
    tooltipPosition,
}: TFileUploadComponentProps) => {
    const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (relatedComponent.current) {
            const htmlElement = relatedComponent.current;
            if (htmlElement.ondrop) {
                htmlElement.ondrop({
                    preventDefault: e.preventDefault,
                    stopPropagation: e.stopPropagation,
                    dataTransfer: {
                        files: e.target.files,
                    },
                } as DragEvent);
            }
        }
    };

    const fileRef = useRef<HTMLInputElement>(null);
    const tooltipClass = `${tooltipPosition}`;

    return (
        <>
            <div className="d-flex flex-row-reverse">
                {fileToolTipText && (
                    <div
                        className={`fileTooltip ${tooltipClass} `}
                        data-tooltip={fileToolTipText}
                        role="button"
                    >
                        <i
                            className="fa-regular fa-pen-to-square fa-xs p-2 w-100 h-100 text-center shadow d-flex justify-content-center align-items-center bg-light rounded-circle"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (fileRef.current) {
                                    fileRef.current.click();
                                }
                            }}
                        ></i>
                    </div>
                )}
                {!fileToolTipText && (
                    <button
                        className="btn btn-outline-info"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (fileRef.current) {
                                fileRef.current.click();
                            }
                        }}
                    >
                        Click to Upload
                    </button>
                )}
            </div>
            <input
                type="file"
                ref={fileRef}
                onChange={fileChangeHandler}
                className="d-none"
            ></input>
        </>
    );
};
