import { MouseEvent } from "react";
import { Header } from "../common/header";
import { Footer } from "./main-page/footer";
import { useSubscriptionService } from "../../services/subscriptions";

type TSubscriptionCard = {
    redirectionLink: string;
    title: string;
    price: string;
    subtitle: string;
    color: string;
    months: "12" | "6" | "1";
};

const labBenefits = [
    {
        title: "Easy-to-use dashboard",
        description: "Manage your postings and applications seamlessly.",
    },
    {
        title: "Direct communication between labs and techs",
        description:
            "Chat directly with technicians to speed up the hiring process.",
    },
    {
        title: "AI tech/lab matching feature",
        description:
            "WinWinGo will provide a list of suggested candidates based on AI-driven analysis of position skills and requirements.",
    },
    {
        title: "Target techs looking to live in your area",
        description:
            "Attract technicians that want to relocate to your lab's location.",
    },
    {
        title: "Dental industry-specific features",
        description: "Specialized tools and filters tailored for dental labs.",
    },
];

const SubscriptionCard = ({
    title,
    price,
    subtitle,
    color,
    redirectionLink,
    months,
}: TSubscriptionCard) => {
    const { generateSubscriptionSession } = useSubscriptionService();

    const clickHandler = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = await generateSubscriptionSession(months);
        //        window.location.href = redirectionLink;
    };

    return (
        <div
            data-testid="subscription_card"
            className="card shadow"
            style={{ backgroundColor: color }}
        >
            <div className="card-title text-center pt-3">
                <h3 className="text-white px-2">{title}</h3>
            </div>
            <div className="card-body bg-light  text-center">
                <h2 className="card-text pb-2 fw-bold" style={{ color: color }}>
                    {price}
                </h2>
                <p>
                    <strong>{subtitle}</strong>
                </p>
            </div>
            <div className="p-3 bg-light rounded-bottom">
                <button
                    className="btn w-100 fw-bold text-white"
                    data-testid="subscription-button"
                    onClick={clickHandler}
                    style={{ backgroundColor: color }}
                >
                    Subscribe
                </button>
            </div>
        </div>
    );
};

export const SubscriptionSelection = () => {
    return (
        <>
            <Header title="Simple pricing for Labs" />
            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="mb-4">
                            <h5 className="text-center">
                                Get full access to all premium features for just
                                $299/month, and enjoy your first month on us!
                            </h5>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6 mb-3">
                            <ul className="">
                                {labBenefits.map((benefit, index) => (
                                    <li key={index} className="pb-2">
                                        <i className="bi bi-check-circle-fill text-primary me-2"></i>
                                        <strong>{benefit.title}</strong>
                                        <p className="small text-muted">
                                            {benefit.description}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-4 mb-3">
                            <SubscriptionCard
                                months="1"
                                color="#6468fe"
                                price="$299 USD/month"
                                subtitle="Get access to top dental techs and premium features designed to match your lab’s needs"
                                title="1st month free!"
                                redirectionLink={
                                    process.env
                                        .REACT_APP_SUBSCRIPTION_1 as string
                                }
                            />
                        </div>
                        <div className="col-lg-1"></div>

                        {/* <div className="col-md-4 mb-3">

                            <SubscriptionCard
                                months="6"
                                color="#f4b355"
                                price="60 USD"
                                subtitle="Six month subscription"
                                title="Half Year"
                                redirectionLink={process.env.REACT_APP_SUBSCRIPTION_6 as string}

                            />
                        </div>
                        <div className="col-md-4 mb-3">
                            <SubscriptionCard
                                months="12"
                                color="#5fb57c"
                                price="100 USD"
                                subtitle="One year subscription"
                                title="Yearly"
                                redirectionLink={process.env.REACT_APP_SUBSCRIPTION_12 as string}

                            />

                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
