import { Header } from "../common/header"
import { Footer } from "./main-page/footer"

export const ContactUs = () => {

    return (
        <>
            <Header title="Contact Us" />
            <div className="container-fluid">
                <div className="container">

                    <div className="row bg-light pt-5 pb-0 pb-md-5 rounded gap-4">
                        <div className="col-md-7 d-inline-flex align-items-center flex-column my-5" >
                            <div className="text-start">
                                <p>Feel free to reach out to us anytime!</p>
                                <br />
                                <div className="ps-3">
                                    <p className="m-0"><i className="fa-solid fa-envelope me-2"></i>
                                        <strong>Email:</strong></p>
                                    <p className="">info@winwingo.com</p>
                                    {/* <p className="m-0"><i className="fa-solid fa-phone me-2"></i>
                                        <strong>Phone:</strong></p>
                                    <p className="">541. 779. 6503</p> */}
                                    <p className="m-0"><i className="fa-solid fa-location-dot me-2"></i>
                                        <strong>Address:</strong></p>
                                    <p className="">1150 Crater Lake Ave. Suite H Medford, OR. 97504</p>
                                </div>
                                <br />
                                <p>We're here to help and would love to hear from you.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="rounded w-100 shadow mt-5"
                                src="https://images.unsplash.com/photo-1590650153855-d9e808231d41?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                alt="Woman with Laptop" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};