import { useAuthContext } from "../../contexts/auth-context";
import SuprSendInbox from "@suprsend/react-inbox";
import "react-toastify/dist/ReactToastify.css";
import {
    TMessageActions,
    TMessageGotRoutes,
    TMessageStatus,
} from "../../lib/types/general";
import { useRouteNavigation } from "../../lib/common/routes";
import {
    acceptInvitation,
    rejectInvitation,
} from "../../services/notifications";
type TNotificationsV2Props = {
    showForSmallDevice: boolean;
};
export const NotificationsV2 = ({
    showForSmallDevice,
}: TNotificationsV2Props) => {
    const { userInfo, isLoggedIn, getUserTokenId } = useAuthContext();
    const canShowNotifications =
        isLoggedIn && userInfo && process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY;
    const notificationsNumberStyle: React.CSSProperties = {
        backgroundColor: "red",
        color: "white",
        padding: "2px 5px",
        borderRadius: "100rem",
        fontSize: "0.6rem",
        fontWeight: "bold",
        marginLeft: "-5px",
        position: "absolute",
        right: "-7px",
        top: "-10px",
    };

    const themeSmallDevice = {
        //bell: { color: "#fff" },
        //badge: { backgroundColor: darkColors.primary },
        // header: {
        //     container: {
        //         backgroundColor: darkColors.main,
        //         borderBottom: `0.5px solid ${darkColors.border}`,
        //         boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)",
        //     },
        //     headerText: { color: darkColors.primaryText },
        //     markAllReadText: { color: darkColors.primary },
        // },
        // tabs: {
        //     color: darkColors.primaryText,
        //     unselectedColor: darkColors.secondaryText + "D9",
        //     bottomColor: darkColors.primary,
        //     badgeColor: "rgba(100, 116, 139, 0.5)",
        //     badgeText: darkColors.primaryText,
        // },
        notificationsContainer: {
            container: {
                scrollbarWidth: "thin",
                overflowX: "hidden",
                textAlign: "left",
                width: "100vw",
                marginLeft: "0px",
                marginRight: "0px",
                height: "100vh",
                transform: "translate3d(0px, -70px, 0px)",
                //left: "-1rem",
                //position: "fixed",
            },
            // noNotificationsText: {
            //     color: darkColors.primaryText,
            // },
            // noNotificationsSubtext: {
            //     color: darkColors.secondaryText,
            // },
            // loader: { color: darkColors.primary },
        },
        // notification: {
        //     container: {
        //         borderBottom: `1px solid ${darkColors.border}`,
        //         readBackgroundColor: darkColors.main,
        //         unreadBackgroundColor: "#273244",
        //         hoverBackgroundColor: "#2D3A4D",
        //     },
        //     pinnedText: {
        //         color: darkColors?.secondaryText,
        //     },
        //     pinnedIcon: {
        //         color: "red",
        //     },
        //     headerText: { color: darkColors.primaryText },
        //     bodyText: {
        //         color: darkColors.secondaryText,
        //         blockquoteColor: "rgba(100, 116, 139, 0.5)",
        //         tableBorderColor: "rgba(100, 116, 139, 0.5)",
        //     },
        //     unseenDot: { backgroundColor: darkColors.primary },
        //     createdOnText: { color: darkColors.secondaryText },
        //     subtext: { color: "#94a3b8" },
        //     actions: [
        //         { container: { backgroundColor: darkColors.primary } },
        //         {
        //             container: {
        //                 borderColor: darkColors.border,
        //                 backgroundColor: "transparent",
        //                 hoverBackgroundColor: darkColors.main,
        //             },
        //             text: { color: darkColors.secondaryText },
        //         },
        //     ],
        //     expiresText: {
        //         backgroundColor: "rgba(100, 116, 139, 0.5)",
        //         color: darkColors.secondaryText,
        //         expiringBackgroundColor: "rgba(217, 45, 32, 0.15)",
        //         expiringColor: darkColors.error,
        //     },
        //     actionsMenuIcon: {
        //         color: darkColors.secondaryText,
        //         hoverBackgroundColor: "rgba(100, 116, 139, 0.5)",
        //     },
        //     actionsMenu: {
        //         backgroundColor: darkColors.main,
        //         borderColor: darkColors.border,
        //     },
        //     actionsMenuItem: {
        //         hoverBackgroundColor: "rgba(100, 116, 139, 0.2)",
        //     },
        //     actionsMenuItemIcon: { color: darkColors.secondaryText },
        //     actionsMenuItemText: {
        //         color: darkColors.secondaryText,
        //     },
        // },
        // toast: {
        //     container: {
        //         backgroundColor: darkColors.main,
        //         borderColor: darkColors.border,
        //     },
        //     headerText: { color: darkColors.primaryText },
        //     bodyText: {
        //         color: darkColors.secondaryText,
        //         blockquoteColor: darkColors.border,
        //         tableBorderColor: darkColors.border,
        //     },
        // },
    };
    const themeBigDevice = {
        notificationsContainer: {
            container: {
                scrollbarWidth: "thin",
                overflowX: "hidden",
                textAlign: "left",
            },
        },
    };
    const { navigateToRoute } = useRouteNavigation();

    const resolveURL = async (
        action: TMessageActions,
        data: unknown,
        extraData: any
    ) => {
        if (action === "goto") {
            switch (data as TMessageGotRoutes) {
                case "dentalTechInfo":
                    navigateToRoute("dentalTechInfo", extraData);
                    break;
                case "dentalLabInfo":
                    navigateToRoute("dentalLabInfo", extraData);
                    break;
                case "subscriptionSelection":
                    navigateToRoute("subscriptionSelection");
                    break;
                default:
                    break;
            }
            return;
        }

        if (action === "invitation") {
            switch (data as TMessageStatus) {
                case "accepted":
                    acceptInvitation(await getUserTokenId(), extraData);
                    break;
                case "rejected":
                    rejectInvitation(await getUserTokenId(), extraData);
                    break;
                default:
                    break;
            }
            return;
        }
        return "";
    };

    type TSuprSendNotificationData = {
        message: {
            url: string;
            actions: {
                url: string;
            }[];
        };
    };
    const customClickHandler = (
        notificationData: TSuprSendNotificationData,
        actionIndex?: number
    ) => {
        const message = notificationData.message;
        if (!notificationData) return false;
        if (
            (!message || !message.actions || !message.actions.length) &&
            !message.url
        )
            return;

        const [action, where, extraData] = (
            actionIndex === undefined
                ? notificationData.message.url
                : notificationData.message.actions[actionIndex].url
        ).split("::") as [TMessageActions, unknown, any];
        resolveURL(action, where, extraData);
    };
    return (
        <>
            {canShowNotifications && (
                <span>
                    <SuprSendInbox
                        headerRightComponent={
                            !showForSmallDevice
                                ? undefined
                                : ({ closeInboxPopup }) => (
                                      <>
                                          <button
                                              className="btn btn-link w-auto text-black"
                                              onClick={closeInboxPopup}
                                          >
                                              <i className="fa-solid fa-circle-xmark"></i>
                                          </button>
                                      </>
                                  )
                        }
                        primaryActionClickHandler={(e) =>
                            customClickHandler(e, 0)
                        }
                        secondaryActionClickHandler={(e) =>
                            customClickHandler(e, 1)
                        }
                        notificationClickHandler={(e) =>
                            customClickHandler(e, undefined)
                        }
                        theme={
                            showForSmallDevice
                                ? themeSmallDevice
                                : themeBigDevice
                        }
                        badgeComponent={({ count }) => (
                            <span
                                data-testid="notifications-alarm"
                                style={notificationsNumberStyle}
                            >
                                <strong>{count}</strong>
                            </span>
                        )}
                        bellComponent={() => (
                            <i
                                data-testid="notifications-bell"
                                className="fa-regular fa-bell text-white fs-5"
                            ></i>
                        )}
                        workspaceKey={
                            process.env
                                .REACT_APP_SUPRSEND_WORKSPACE_KEY as string
                        }
                        subscriberId={userInfo.suprSend.subscriberId}
                        distinctId={userInfo.id}
                    />
                </span>
            )}
        </>
    );
};
