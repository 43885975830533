import { useEffect, useState } from "react";
import { useAuthContext } from "../../../../contexts/auth-context"
import {
    TReportedUserRow, getReportedUsers as _getReportedUsers,
    enableOrDisableUser as _enableOrDisableUser,
    updateReportState as _updateReportState, TReportState, TReportedUsersFilters
} from "../../../../services/backoffice/backoffice-service";
import { TProcessStates } from "../../../../lib/types/general";

export type TEnableDisableUserHandler = (userId: string, enabled: boolean) => Promise<unknown>;
export type TUpdateReportStateHandler = (reported_user_id: string, state: TReportState) => Promise<any>

export const useReportMainBusinessLogic = () => {
    const { getUserTokenId } = useAuthContext();
    const [workingState, setWorkingState] = useState<TProcessStates>("idle");
    const [reportedUserList, setReportedUserList] = useState<TReportedUserRow[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentPage, setCurrentPage] = useState<number>(0);

    const getReportedUsers = async (filters: TReportedUsersFilters, offset: number) => {
        setWorkingState("working");
        const userTokenId = await getUserTokenId();
        const _response = await _getReportedUsers(offset, userTokenId, filters);
        setReportedUserList(_response.data.data.users);
        setTotalRows(_response.data.data.totalRows);

        setWorkingState("done");
        return filters.filterUserNames;
    }

    const enableOrDisableUser: TEnableDisableUserHandler = async (userId: string, enabled: boolean) => {
        const userTokenId = await getUserTokenId();
        return _enableOrDisableUser(userId, enabled, userTokenId);
    }

    const updateReportState: TUpdateReportStateHandler = async (reported_user_id: string, state: TReportState) => {
        const userTokenId = await getUserTokenId();
        return _updateReportState(state, reported_user_id, userTokenId)
    }

    useEffect(() => {
        getReportedUsers({
            filterStates: [],
            filterUserNames: [],
        }, currentPage);
        //        Promise.all([refreshUserStats(), refreshReportStats()])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        reportedUserList,
        totalRows,
        currentPage,
        workingState,
        enableOrDisableUser,
        getReportedUsers,
        updateReportState,
    }
}