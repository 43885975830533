import { MouseEvent, useEffect, useRef, useState } from "react";
import { NotificationProvider } from "../../../contexts/notification-context";
import { useFileManagement } from "../../../services/file-management";
import {
    DentalTechSearchFilterPanel,
    TTechnicianFilterSettings,
} from "../../common/dental-tech-search-filter-panel";
import { Header } from "../../common/header";
import { Footer } from "../main-page/footer";
import { useTechnicians } from "../../../services/technicians";
import { TApiTechnician } from "../../../lib/types/dentalTechs";
import { InviteButton } from "../../common/invite-button";
import { getIndustryRoles } from "../../../services/generalInfo";
import { TGenericIdName } from "../../../lib/types/general";
import { useRouteNavigation } from "../../../lib/common/routes";
import { getCacheKillQueryString } from "../../../lib/common/api";
import {
    ASSETS_ROUTES,
    TECH_SEARCH_PAGINATION_SIZE,
} from "../../../lib/common/constants";
import { Pagination } from "../../common/pagination";
import { getRegions } from "../../../services/regions";
import { Spinner } from "../../common/spinner";
import { BackButton } from "../../common/backbutton";

type TDentalTechInfoProps = {
    userInfo: TApiTechnician;
    industryRoles: TGenericIdName[];
    desiredRegion: TGenericIdName[];
};

const currencyFormater = new Intl.NumberFormat();

export const DentalTechInfo = (props: TDentalTechInfoProps) => {
    const { userInfo, industryRoles, desiredRegion } = props;
    const { getImagePath } = useFileManagement();
    const { navigateRouteClickHandler } = useRouteNavigation();
    const [imgLogo, setImgLogo] = useState<string | null>(null);

    useEffect(() => {
        setImgLogo(
            getImagePath("logo", "fixed", userInfo.id) +
                "?" +
                getCacheKillQueryString()
        );
        //eslint-disable-next-line
    }, []);

    // const imgBackground = userInfo.images?.background && getImagePath(userInfo.images?.background, "fixed", userInfo.id);
    const skillList = !userInfo.other.positions
        ? []
        : userInfo.other.positions.map(
              (posId) => industryRoles.find((rol) => rol.id === posId)?.name
          );

    const SkillList = () => {
        const [isExpanded, setIsExpanded] = useState<boolean>(false);

        const totalSkills = skillList.length;
        const showSkills = 3;
        const remainingSkills = totalSkills - showSkills;

        const miniList = skillList.slice(0, showSkills);
        const totalList = skillList;

        return (
            <>
                {isExpanded ? (
                    <>
                        {totalList.map((skill, index) => (
                            <small
                                key={index}
                                className="text-wrap text-start badge rounded-pill border border-2 border-info me-2 mb-1 text-dark"
                            >
                                {skill}
                            </small>
                        ))}
                        <button
                            className="btn btn-link text-info p-0"
                            style={{ fontSize: "small" }}
                            onClick={() => setIsExpanded(false)}
                        >
                            show less
                        </button>
                    </>
                ) : (
                    <>
                        {miniList.map((skill, index) => (
                            <small
                                key={index}
                                className="text-wrap text-start badge rounded-pill border border-2 border-info me-2 mb-1 text-dark"
                            >
                                {skill}
                            </small>
                        ))}
                        {remainingSkills > 0 && (
                            <>
                                <button
                                    className="btn btn-link text-info p-0"
                                    style={{ fontSize: "small" }}
                                    onClick={() => setIsExpanded(true)}
                                >
                                    ... and {remainingSkills} more
                                </button>
                            </>
                        )}
                    </>
                )}
            </>
        );
    };

    const goToTechClickHandler = (e: MouseEvent) => {
        navigateRouteClickHandler("dentalTechInfo", e, userInfo.id, true);
    };

    const title = `${userInfo.name}\nsent you an\ninvitation request`;
    return (
        <div className="col-lg-4 col-md-6 mb-4 px-3" key={userInfo.id}>
            <div className="card p-3 shadow rounded-3 h-100 card-tech-top border-0">
                <div
                    className="card-title row position-relative text-center text-md-start pb-2"
                    onClick={goToTechClickHandler}
                    role="button"
                    // style={{
                    //     [imgBackground ? 'backgroundImage' : '']: `url(${imgBackground})`
                    // }}
                >
                    <div className="col-md-4 text-center">
                        {imgLogo && (
                            <img
                                className="card-img-top rounded-circle shadow card-tech-top__logo border border-info border-4"
                                alt="hgap"
                                src={imgLogo}
                                onError={() =>
                                    setImgLogo(ASSETS_ROUTES.IMGS.techAvatarUrl)
                                }
                            />
                        )}
                    </div>
                    <div className="col-md-8">
                        <h6 className="fw-bold mt-3 mt-md-0">
                            {userInfo.name}
                            <br />
                        </h6>
                        {userInfo.other.desiredRegion !== undefined && (
                            <p>
                                <i className="fa-solid fa-earth-americas text-info me-2">
                                    {" "}
                                </i>
                                <small>
                                    {
                                        desiredRegion.find(
                                            (region) =>
                                                region.id ===
                                                userInfo.other.desiredRegion
                                        )?.name
                                    }
                                </small>
                            </p>
                        )}
                    </div>
                </div>
                <div className="position-absolute top-0 end-0 m-3">
                    {userInfo.inmycontactlist && (
                        <div
                            className="invitation-accepted rounded-3 shadow bg-info text-white"
                            data-tooltip="In Contact"
                            data-testid="inContact-icon"
                        >
                            <i className="fa-solid fa-user-check fa-xs"></i>
                        </div>
                    )}
                    {!userInfo.inmycontactlist &&
                        userInfo.invitationsenttome && (
                            <div
                                className="invitation-requested rounded-3 shadow bg-warning"
                                data-tooltip={title}
                            >
                                <i className="fa-solid fa-user-clock fa-xs"></i>
                            </div>
                        )}
                </div>
                <div className="card-body text-start p-0">
                    <div className="row mb-3">
                        <div className="col-4 fw-bold">
                            <small>Experience</small>
                        </div>
                        <div className="col-8">
                            {!!userInfo?.other.yearsOfExperience && (
                                <small>
                                    {userInfo?.other?.yearsOfExperience} years
                                </small>
                            )}
                            {!!!userInfo?.other.yearsOfExperience && (
                                <small className="no-data">
                                    No experience to show
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-4 fw-bold">
                            <small>Desired Salary</small>
                        </div>
                        <div className="col-8">
                            {!!userInfo.other.desiredSalaryPerYear && (
                                <small>
                                    {currencyFormater.format(
                                        userInfo?.other?.desiredSalaryPerYear
                                    ) &&
                                        `$ ${currencyFormater.format(
                                            userInfo?.other
                                                ?.desiredSalaryPerYear
                                        )} yearly`}
                                </small>
                            )}
                            {!!!userInfo.other.desiredSalaryPerYear && (
                                <small className="no-data">
                                    No salary to show
                                </small>
                            )}
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4 fw-bold">
                            <small>Skillset</small>
                        </div>
                        <div className="col-8 mt-lg-2">
                            <SkillList />
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 bg-transparent p-0">
                    {!userInfo.inmycontactlist &&
                        !userInfo.invitationsenttome &&
                        userInfo.invitationsentstate !== "accepted" && (
                            <InviteButton
                                userToInviteId={userInfo.id}
                                userToInviteInvitationState={
                                    userInfo.invitationsentstate
                                }
                                userType="tech"
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export const DentalTechSearch = () => {
    const { getTechSearch, state } = useTechnicians();
    const [technicians, setTechnicians] = useState<TApiTechnician[]>([]);
    const [industryRoles, setIndustryRoles] = useState<TGenericIdName[]>([]);
    const [region, setRegion] = useState<TGenericIdName[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const refFilter = useRef<HTMLDivElement>(null);
    const regionFilters = useRef<string[]>();
    const industryRolesFilters = useRef<string[]>();
    const nameFilter = useRef<string[]>();

    useEffect(() => {
        getIndustryRoles().then((roles) => {
            setIndustryRoles(roles);
            // refreshList();
        });
        getRegions().then((region) => {
            setRegion(region);
        });
        // eslint-disable-next-line
    }, []);

    const refreshList = async (offset: number) => {
        const techs = await getTechSearch(
            {
                filterPositions: industryRolesFilters.current,
                filterRegions: regionFilters.current,
                filterName: nameFilter.current,
            },
            offset
        );
        setTechnicians(techs.users);
        setTotalCount(techs.totalCount);
        setPageNumber(offset + 1);
    };

    const resetCallback = () => {
        regionFilters.current = undefined;
        industryRolesFilters.current = undefined;
        nameFilter.current = undefined;
        refreshList(0);
    };

    const filterCallback = async (
        filterSettings: TTechnicianFilterSettings
    ) => {
        const { regions, industryRoles, names } = filterSettings;

        regionFilters.current = regions ? regions.map((o) => o.data.id) : [];
        industryRolesFilters.current = industryRoles
            ? industryRoles.map((o) => o.data.id)
            : [];
        nameFilter.current = names ? names.map((o) => o.data.name) : [];

        await refreshList(0);
    };

    return (
        <>
            <NotificationProvider>
                <Header title="Explore Qualified Dental Techs" />

                <div className="container-fluid">
                    <div className="m-3" ref={refFilter}>
                        <BackButton btnText=" Back to Dashboard" />
                    </div>
                    <div className="py-3">
                        <DentalTechSearchFilterPanel
                            searchJustFromContactList={false}
                            filterCallback={filterCallback}
                            resetCallback={resetCallback}
                        />
                    </div>
                </div>
                <div className="container-fluid mt-3">
                    <div className="container">
                        {state === "working" && (
                            <div className="vh-100">
                                <Spinner />
                            </div>
                        )}
                        {state === "idle" && technicians.length > 0 && (
                            <>
                                <div className="row">
                                    {technicians.map((userInfo) => (
                                        <DentalTechInfo
                                            industryRoles={industryRoles}
                                            userInfo={userInfo}
                                            desiredRegion={region}
                                        />
                                    ))}
                                </div>
                                <Pagination
                                    currentPage={pageNumber}
                                    totalCount={totalCount}
                                    itemsPerPage={TECH_SEARCH_PAGINATION_SIZE}
                                    refObject={refFilter}
                                    onPageChange={(offset) => {
                                        refreshList(offset);
                                    }}
                                />
                            </>
                        )}
                        {state === "idle" && technicians.length === 0 && (
                            <p className="text-center text-warning vh-100">
                                No matching technicians found.
                            </p>
                        )}
                    </div>
                </div>
                <Footer />
            </NotificationProvider>
        </>
    );
};
